import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppService } from 'src/app/__core/services/app.service';
import { TranslationService } from 'src/app/__core/services/translation.service';
import { animate, animateChild, query, stagger, style, transition, trigger } from '@angular/animations';
import { MatSnackBarService } from 'src/app/__core/services/matSnackBar.service';
import { AuthService } from '../../auth.service';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/__core/interfaces/user';
import { SharedService } from 'src/app/__shared/shared.service';

@Component({
  selector: 'ess-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('stagger', [
      transition('* => *', [
        query(':enter', stagger(60, animateChild()), { optional: true }),
      ])
    ]),
    trigger('fadeInUp', [
      transition(':enter', [
        style({
          transform: 'translateY(50px)',
          opacity: 0
        }),
        animate(`500ms cubic-bezier(0.35, 0, 0.25, 1)`, style({
          transform: 'translateY(0)',
          opacity: 1
        }))
      ]),
      transition(':leave', [
        style({
          transform: 'translateX(0)',
          opacity: 1
        }),
        animate(`500ms cubic-bezier(0.35, 0, 0.25, 1)`, style({
          transform: 'translateX(50px)',
          opacity: 0
        }))
      ]),
    ])
  ],
})
export class LoginComponent implements OnInit, AfterViewInit {

  @Output() navigationTo = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() isLoggedIn = new EventEmitter();


  currentStep: 'loginStep' | 'verificationCodeStep' = 'loginStep';
  verificationCode = new FormControl();


  TOKEN: string = "";

  pageName: string = "login";
  baseHeaderImagePath: string = "assets/images/_header/";
  currentLanguage: any = this.lang.currentLanguage;
  form: FormGroup
  User: User | null = null;

  imageCapcha: string = ""
  tokenCapcha: string = ""

  constructor(
    public app: AppService,
    private lang: TranslationService,
    private snack: MatSnackBarService,
    private sharedService: SharedService,
    private service: AuthService,
    private cd: ChangeDetectorRef,
  ) { }



  ngOnInit(): void {
    this.form = new FormGroup({
      // email: new FormControl('', Validators.compose([
      //   Validators.required, Validators.email
      // ])),
      password: new FormControl('', Validators.required),
      IdNumber: new FormControl('', Validators.compose([
        this.sharedService.checkValidityNumber,
        Validators.required
      ])),
      CaptchaString: new FormControl('', Validators.required),
    })

    this.getUser();
  }

  ngAfterViewInit(): void {
    this.GetCaptcha();
  }

  GetCaptcha = () => {
    this.sharedService.GetCaptcha().subscribe(res => {
      if (res.errorCode == 0) {
        this.imageCapcha = (environment.baseUrl + "File/" + res.data?.imagePath || "")
        this.tokenCapcha = res.data?.token || "";
        this.cd.detectChanges()
      } else {
        this.snack.error(res.errorMessage);
      }
    })
  }

  navigationPageTo = (pageName: any) => {
    this.navigationTo.emit(pageName)
  }

  closePopup = () => {
    this.close.emit(true);
  }

  login = () => {
    this.service.isUserLoggedIn().subscribe(res => {
      res
    });

    if (this.form.invalid) {
      this.snack.error(this.lang.translate('pleaseFillAllRequiredFields'));
      this.form.markAllAsTouched();
      return;
    }

    let req = new FormData();
    // req.append('Username', this.form.get('email')?.value);
    req.append('Password', this.form.get('password')?.value);

    req.append('IdNumber', this.form.get('IdNumber')?.value);
    req.append('CaptchaToken', this.tokenCapcha);
    req.append('CaptchaString', this.form.get('CaptchaString')?.value);

    this.service.login(req).subscribe(res => {
      if (res.errorCode == 0) { 
        this.TOKEN = res.data.token;
        this.currentStep = "verificationCodeStep";
        this.cd.detectChanges();
        this.cd.detectChanges();
        this.cd.detectChanges();
        this.cd.detectChanges();
        this.cd.detectChanges(); 
        setTimeout(() => {
          this.cd.detectChanges(); 
        }) 
      } else {
        this.snack.error(res.errorMessage);
      }
    })
  }

  getUser = () => {
    this.service.isUserLoggedIn().subscribe(user => this.User = user ? user : null);
  }


  verify = () => {
    if (this.verificationCode.invalid) {
      this.snack.error(this.lang.translate('pleaseFillAllRequiredFields'));
      this.form.markAllAsTouched();
      return;
    }
  
    let req = new FormData();
    req.append('Token', this.TOKEN);
    req.append('VerificationCode', this.verificationCode?.value);
  
    this.service.verifyLogin(req).subscribe(res => {
      if (res.errorCode == 0) {

        this.close.emit(true);
        this.snack.success(this.lang.translate('loggedInSuccessfully')); 
        this.service.user$.next(res.data); 
        localStorage.setItem('UserData', JSON.stringify(res.data));
        localStorage.setItem(environment.appName, res.data.token);
        this.isLoggedIn.emit(true) 
      } else {
        this.snack.error(res.errorMessage);
      }
    })
  } 
  

}
